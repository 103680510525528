import { Card } from "primereact/card";
import TemplateDemo from "../TopBar/TopBar";
import { InputText } from "primereact/inputtext";
import "./Login.css";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../services/auth";
import { useMutation } from '@tanstack/react-query';
import {  getRouteForRole } from "../constants";
import { Toast } from "primereact/toast";

/**
 * User login form
 */
export default function Login() {

  // Form state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const toastRef = useRef(null);

  const navigate = useNavigate();

  const loginMutation = useMutation(
    {
      mutationFn: authLogin,
      onSuccess: (response) => {
        if (response.type === "success") {
          const data = response.data;
          localStorage.setItem('token', data.token);
          localStorage.setItem('role', data.user.role);
          const route = getRouteForRole(data.user.role);
          navigate(route);
          // if (data.user.role === UserRole.MEDICAL_CENTER) { 
          //   navigate('/anticipos');
          // } else {
          //   navigate('/casos');
          // }
        } else {
          toastRef.current.show({ severity: 'error', summary: 'Error', detail: response.message });
        }
      },
      onError: (error) => {
        console.error('Error fetching login', error);
        toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error' });
      },
    }
  );

  const handleLogin = (e) => {
    e.preventDefault();
    loginMutation.mutate({ username: email, password });
  };

  return (
    <>
      <TemplateDemo />
      <Toast ref={toastRef} position="top-center" />
      <div className="login-container">
        <Card title="Iniciar sesión">
          <form className="p-fluid" onSubmit={handleLogin}>
            <div className="p-field">
              <label htmlFor="email">Correo</label>
              <InputText
                id="email"
                name="email"
                type="email"
                value={email}
                keyfilter="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="p-field">
              <label htmlFor="password">Contraseña</label>
              <InputText
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              type="submit"
              label="Ingresar"
              disabled={loginMutation.isPending}
              loading={loginMutation.isPending}
            />
          </form>
        </Card>
      </div>
    </>
  );
}
