import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import FormSubHeading from "../components/FormSubHeading";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import {
  useCentersFromRequirements,
  useMedicalCenters,
  useMedicalCenterClientRequirements,
  useRequirements,
  useRequirementTypes
} from "../hooks/queries";
import { MultiSelect } from "primereact/multiselect";

/**
 * @typedef TableRequirement Structure of each requirement shown on the table
 * @property {number} requirementType
 * @property {string} requirementTypeLabel
 * @property {number} requirement
 * @property {string} requirementLabel
 * @property {number} medicalCenter
 * @property {string} medicalCenterLabel
 */


/**
 * Modal para registrar un requerimiento
 * @param {object} props
 * @param {boolean} props.showModal
 * @param {() => void} props.onHide
 * @param {React.Dispatch<React.SetStateAction<TableRequirement[] | null>>} props.setRequirementList
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setInputsLocked
 * @param {number} props.patientClient
 * @param {{ requirementType: number, requirement: number } | undefined} props.initialValues
 * @param {number | null} props.centerSelected
 * @param {React.Dispatch<React.SetStateAction<number | null>>} props.setCenterSelected
 */
export default function RequirementModal({
  showModal,
  onHide,
  setRequirementList,
  setInputsLocked,
  initialValues,
  patientClient,
  centerSelected,
  setCenterSelected,
}) {

  // Form state
  const [requirementType, setRequirementType] = useState(null);
  const [requirement, setRequirement] = useState([]);
  const [medicalCenterLabel, setMedicalCenterLabel] = useState("");
  const [medicalCenter, setMedicalCenter] = useState(null);

  // Set initial form values on props change
  useEffect(function setInitialFormValues() {
    if (initialValues) {
      setRequirementType(initialValues.requirementType);
      setRequirement(initialValues.requirement);
    }
  }, [initialValues]);

  

  // Check all fields are set to allow saving
  const canSave = requirementType !== null && requirement !== null && medicalCenter !== null;

  // Do necessary queries
  const requirementTypesQuery = useRequirementTypes();
  const requirementsQuery = useRequirements(requirementType);
  const medicalCentersQuery = useMedicalCenters();
  
  // Used for the first requirement added
  const requirementMedicalCentersQuery = useCentersFromRequirements({
    requirements:requirement,
    client: patientClient,
    enabled: requirement!==null && requirement.length !==0 && patientClient !== null && centerSelected === null,
  });

  // Used for the second requirement
  const clientTypeMedicalCenterRequirements = useMedicalCenterClientRequirements({
    centerId: centerSelected,
    client: patientClient,
    requirementType: requirementType,
  });

  // Based on status of centerSelected, determine which requirements will be shown on dropdown
  let requirementOptions = requirementsQuery.data ?? [];
  if (centerSelected) {
    requirementOptions = clientTypeMedicalCenterRequirements.data ?? [];
  }

  /**
   * Save or modify case requirement
   */
  function saveRequirement() {
    // Edit requirement
    if (initialValues) {
      setRequirementList((prev) => {
        const newList = prev?.map((req) => {
          // If has found requirement to edit
          if (req.requirement === initialValues.requirement) {
            return {
              // Requirement type
              requirementType,
              requirementTypeLabel: requirementTypesQuery.data.find((type) => type.id === requirementType).name,
              // Requirement
              requirement: requirement[0],
              requirementLabel: requirementsQuery.data.find((r) => r.id === requirement[0]).name,
              // Medical center
              medicalCenter: Number(medicalCenter),
              medicalCenterLabel
            };
          }
          return req;
        }) ?? [];
        return newList;
      });
    } else {
      // Add new requirement
      if (!centerSelected) {
        setCenterSelected(medicalCenter);
      }

      const newRequirements = requirement.map((req) => ({
        requirementType: Number(requirementType),
        requirementTypeLabel: String(
          requirementTypesQuery.data?.find((type) => Number(type.id) === Number(requirementType))?.name
        ),
        requirement: Number(req),
        requirementLabel: String(requirementsQuery.data?.find((r) => Number(r.id) === Number(req))?.name),
        medicalCenter: Number(medicalCenter),
        medicalCenterLabel: String(medicalCenterLabel),
      }));

      setRequirementList((prev) => {
        const newList = [
          ...prev,
          ...newRequirements,
        ];
        return newList;
      });
    }
    setRequirementType(null);
    setRequirement([]);
    setInputsLocked("all");
    onHide();
  }

  return (
    <Dialog
      header="Nuevo servicio"
      visible={showModal}
      onHide={onHide}
      draggable={false}
      style={{ width: "40rem" }}
      contentClassName="p-fluid"
    >

      <FormSubHeading title="Servicio médico" />
      <div className="p-field">
        <label htmlFor="requirementType">Tipo de servicio</label>
        <Dropdown
          inputId="requirementType"
          name="requirementType"
          value={requirementType}
          onChange={(e) => {
            setRequirementType(e.value);
            setRequirement(null);
          }}
          options={requirementTypesQuery.data ?? []}
          optionValue="id"
          optionLabel="name"
          loading={requirementTypesQuery.isLoading}
          disabled={requirementTypesQuery.isLoading || initialValues}
          placeholder="Seleccionar tipo"
          filter
        />
      </div>

      <div className="p-field">
        <label htmlFor="requirement">Servicios</label>
        <MultiSelect
          inputId="requirements"
          name="requirements"
          placeholder="Seleccionar servicios"
          value={requirement}
          onChange={(e) => setRequirement(e.value)}
          loading={requirementsQuery.isLoading}
          disabled={requirementType === null || requirementsQuery.isLoading}
          options={requirementOptions}
          optionValue="id"
          optionLabel="name"
          filter
          virtualScrollerOptions={{ itemSize: 48 }}
          emptyMessage="No se hallaron servicios para su búsqueda"
        />
        {/* <Dropdown
          inputId="requirement"
          name="requirement"
          disabled={requirementType === null || requirementsQuery.isLoading}
          value={requirement}
          onChange={(e) => setRequirement(e.value)}
          options={requirementOptions}
          optionValue="id"
          optionLabel="name"
          loading={requirementsQuery.isLoading}
          placeholder="Seleccionar servicios"
          filter
          virtualScrollerOptions={{ itemSize: 48 }}
          emptyMessage="No se hallaron servicios para su búsqueda"
        /> */}
      </div>

      <FormSubHeading title="Ubicación" />
      <div className="p-field">
        {
          centerSelected !== null ? (
            <div className="p-field">
              <span><b>Centro médico:</b> {medicalCenterLabel ?? ""}</span>
            </div>
          ) : (
            <>
              <label htmlFor="medicalCenter">Centro médico</label>
              <Dropdown
                inputId="medicalCenter"
                name="medicalCenter"
                disabled={requirement === null || medicalCentersQuery.isLoading || centerSelected !== null}
                value={medicalCenter}
                onChange={(e) => {
                  setMedicalCenter(e.value);
                  setMedicalCenterLabel(
                    requirementMedicalCentersQuery.data.find((center) => center.medical_center === e.value).medical_center_name
                  );
                }}
                options={requirementMedicalCentersQuery.data ?? []}
                optionValue="medical_center"
                optionLabel="medical_center_name"
                loading={medicalCentersQuery.isLoading}
                placeholder="Seleccionar centro médico"
                emptyMessage="No se hallaron centros médicos para su búsqueda"
                filter
              />
            </>
          )
        }
      </div>

      {/* <div className="p-field">
        <span>Día de atención</span>
        <div className="case-date-selector my-2">
          {DAYS.map((day) => (
            <Button key={day.id} label={`${day.fullLabel} (3 cupos disponibles)`} />
          ))}
        </div>
      </div> */}
      <Button label="Guardar" onClick={saveRequirement} disabled={!canSave} />
    </Dialog>
  )
}
