// Hooks that consume API endpoints
import { useQuery } from "@tanstack/react-query";
import { getDiagnosisList, getRequirementMedicalCenters, getRequirements, getRequirementTypes } from "../services/requirements";
import { getCentersFromRequirements, getClientRequirementsFromCenter, getMedicalCenter, getMedicalCenters, getRequirementsFromCenter } from "../services/medicalCenters";
import { getScaleRequirements, getScales } from "../services/baremos";
import { getClients, getSubsidiaries } from "../services/clients";
import { DAYS } from "../constants";
import { getBanks } from "../services/banks";
import { getAdvancesFromMedicalCenter } from "../services/advances";
import { getCaseById, getCases } from "../services/medicalCases";
import { getCurrentDollarRate } from "../services/dollar";


/**
 * Hook to get requirement types
 */
export function useRequirementTypes() {
  return useQuery({
    queryKey: ["requirementTypes"],
    queryFn: () => getRequirementTypes(),
    staleTime: 1000 * 60 * 60,
  });
}

/**
 * Hook to get all requirements, or filtered by type
 * @param {number | undefined} requirementType 
 */
export function useRequirements(requirementType) {
  return useQuery({
    queryKey: ["requirements", requirementType],
    queryFn: () => getRequirements(requirementType),
  });
}

/**
 * Hook to get all requirements, or filtered by type and companies
 * @param {number | undefined} requirementType 
 * @param {number[]} companies
 */
export function useRequirementsCompanies(requirementType, companies = []) {
  return useQuery({
    queryKey: ["requirements", requirementType, ...companies],
    queryFn: () => getRequirements(requirementType, companies),
    enabled: !!companies.length,
  });
}

/**
 * Hook to get all medical centers
 */
export function useMedicalCenters() {
  return useQuery({
    queryKey: ["medicalCenters"],
    queryFn: () => getMedicalCenters(),
  });
}

/**
 * Hook to get a single medical center
 * @param {number} centerId 
 */
export function useSingleMedicalCenter(centerId) {
  return useQuery({
    queryKey: ["medicalCenter", centerId],
    queryFn: () => getMedicalCenter(centerId),
    staleTime: Infinity,
  })
}

/**
 * Hook to get all medical cases
 */
export function useMedicalCases() {
  return useQuery({
    queryKey: ["medicalCases"],
    queryFn: () => getCases(),
    select: (data) => data.map((medicalCase) => {
      // Get unique requirement types
      const requirementTypesSet = new Set();
      medicalCase.requirements.forEach((req) => requirementTypesSet.add(req.type_requirement));
      // Get all requirement names
      const requirementNameArray = [];
      medicalCase.requirements.forEach((req) => requirementNameArray.push(req.requirement_name));
      return {
        ...medicalCase,
        requirement_type_names: Array.from(requirementTypesSet).join(", "),
        requirement_names: requirementNameArray.join(", "),
      };
    }),
  });
}

/**
 * Hook to get single medical case
 * @param {number | string} caseId
 */
export function useSingleMedicalCase(caseId) {
  return useQuery({
    queryKey: ["medicalCase", Number(caseId)],
    queryFn: () => getCaseById(Number(caseId)),
    select: (data) => ({
      ...data,
      requirements: data.requirements.map((req) => ({
        done: false,
        ...req,
      })),
    }),
  });
}



/**
 * Hook to get medical centers for a requirement
 * @param {number | undefined} requirement 
 */
export function useRequirementMedicalCenters(requirement) {
  return useQuery({
    queryKey: ["reqMedicalCenters", requirement],
    queryFn: () => getRequirementMedicalCenters(requirement),
  });
}

/**
 * @typedef MedicalCenterRequirement
 * @property {number} id
 * @property {object} client
 * @property {number} client.id
 * @property {string} client.name
 * @property {string} company_client_name
 * @property {number} requirement
 * @property {string} requirement_name
 * @property {string} type_requirement_name
 * @property {number | string} price_ref
 * @property {object[]} days
 */

/**
 * Hook to get requirements associated to a medical center
 * @param {number} centerId
 */
export function useMedicalCentersRequirements(centerId) {
  return useQuery({
    queryKey: ["medicalCenter", "requirements", Number(centerId)],
    queryFn: () => getRequirementsFromCenter(centerId),
    select: (data) => data.map((req) => {
      /**@type {MedicalCenterRequirement} */
      const formattedData = {
        id: req.id,
        client: { id: req.company_client, name: req.company_client_name },
        company_client_name: req.company_client_name,
        requirement: req.requirement,
        requirement_name: req.requirement_name,
        type_requirement_name: req.type_requirement_name,
        price_ref: req.requirement_price_ref,
        days: req.requirement_day.map((reqDay) => ({
          ...DAYS.find((day) => day.apiLabel === reqDay.day),
          slots: reqDay.quota,
        })),
      };
      return formattedData;
    }),
  });
}

/**
 * Hook to get all available banks
 */
export function useBanks() {
  return useQuery({
    queryKey: ["banks"],
    queryFn: () => getBanks(),
    staleTime: Infinity,
  });
}

/**
 * Hook to get all advances corresponding to a specific medical center
 * @param {number} centerId
 */
export function useMedicalCenterAdvances(centerId) {
  return useQuery({
    queryKey: ["advances", centerId],
    queryFn: () => getAdvancesFromMedicalCenter(centerId),
  });
}

/**
 * Hook to get list of available diagnosis
 */
export function useDiagnosisList() {
  return useQuery({
    queryKey: ["diagnosisList"],
    queryFn: () => getDiagnosisList(),
    staleTime: Infinity,
  });
}

/**
 * Hook to get list of scales
 * @todo filter by insurance company
 */
export function useScales() {
  return useQuery({
    queryKey: ["scales"],
    queryFn: () => getScales(),
  });
}

/**
 * Hook to get requirements associated with a scale, can filter by requirement type
 * @param {number?} clientId 
 * @param {number?} requirementType
 */
export function useScaleRequirements(clientId, requirementType) {
  return useQuery({
    queryKey: ["scaleRequirements", clientId, { requirementType }],
    queryFn: () => getScaleRequirements(clientId, requirementType),
    enabled: clientId !== null && requirementType !== null,
  });
}

/**
 * Hook to get list of clients
 * @param {boolean | undefined} scaleNull
 */
export function useClients(scaleNull) {
  return useQuery({
    queryKey: ["clients"],
    queryFn: () => getClients(scaleNull),
  });
}

/**
 * Hook to get list of subsidiaries
 */
export function useSubsidiaries() {
  return useQuery({
    queryKey: ["subsidiaries"],
    queryFn: () => getSubsidiaries(),
  });
}

/**
 * Hook to get list of medical centers from a list of requirements
 * @param {object} params
 * @param {number[]?} params.requirements Requirement being requested
 * @param {number?} params.client ID of the client that the patient is associated to
 * @param {boolean} params.enabled If query should be enabled
 */
export function useCentersFromRequirements({ requirements, client, enabled }) {
  return useQuery({
    queryKey: ["medicalCenters", "requirements", { requirements, client }],
    queryFn: () => getCentersFromRequirements({
      requirements, client,
    }),
    enabled,
  });
}

/**
 * Hook to get requirements of a type offered from a medical center to a client
 * @param {object} params
 * @param {number?} params.centerId
 * @param {number?} params.client
 * @param {number?} params.requirementType
 */
export function useMedicalCenterClientRequirements({ centerId, client, requirementType }) {
  return useQuery({
    queryKey: ["medicalCenter", "requirements", Number(centerId), { client, requirementType }],
    queryFn: () => getClientRequirementsFromCenter({
      centerId: Number(centerId),
      client: Number(client),
      requirementType: Number(requirementType)
    }),
    enabled: centerId !== null && client !== null && requirementType !== null,
  });
}


/**
 * Hook to get the current dollar rate
 * 
 */
export function useCurrentDollarRate() {
  return useQuery({
    queryKey: ["dollarRate"],
    queryFn: () => getCurrentDollarRate(),
    // select: (data) => data.oficial.value,
    // staleTime: 1000 * 60 * 60,
  });
}
