import { Dropdown } from "primereact/dropdown";
import { getStatusChangeOptionsForRole } from "../utils";
import StatusTag from "./StatusTag";

/**
 * Dropdown to change status of a case
 * @param {object} props
 * @param {string} props.currentStatus
 * @param {string} props.selectedStatus
 * @param {(e: import("primereact/dropdown").DropdownChangeEvent) => void} props.onChange
 */
export function StatusSelector({ currentStatus, selectedStatus, onChange, disabled }) {
  let options = getStatusChangeOptionsForRole(currentStatus);
  if (currentStatus !== "pendiente") {
    options = options.filter((option) => option !== "pendiente" && option !== "anulado");
  }
  if (currentStatus === "anulado") {
    options = ["anulado"];
  }
  return (
    <Dropdown
      value={selectedStatus}
      disabled={disabled}
      options={options}
      valueTemplate={<StatusTag value={selectedStatus} />}
      itemTemplate={(option) => <StatusTag value={option} />}
      style={{ maxHeight: "3.33rem", maxWidth: "10rem" }}
      onChange={onChange}
    />
  )};
