import { Dialog } from "primereact/dialog";
import StatusTag from "./StatusTag";
import { Button } from "primereact/button";

/**
 * Modal de confirmación para cambiar status de un caso 
 * @param {object} props
 * @param {import("../services/medicalCases").MedicalCase} props.rowChanging
 * @param {string} props.newStatus
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {() => void} props.onSave
 */
export const ChangeStatusSingleModal = ({
  rowChanging,
  newStatus,
  show,
  onHide,
  onSave,
}) => {
  if (!rowChanging || !newStatus) return;
  return (
    <Dialog
      header="Modificar status"
      visible={show}
      style={{ maxWidth: "35rem" }}
      onHide={onHide}
      draggable={false}
    >
      <div className="p-fluid text-center">
        <p>¿Seguro que deseas cambiar el status de la orden #{rowChanging.id}?</p>
        <p><b>De <StatusTag value={rowChanging.status} /> a <StatusTag value={newStatus} /></b></p>
      </div>
      <br></br>
      <div className="delete-confirm-buttons-container">
        <Button type="button" label="Cambiar status" onClick={onSave} />
        <Button type="button" label="Cancelar" style={{ backgroundColor: "#999999", borderColor: "#999999" }} onClick={onHide} />
      </div>
    </Dialog>
  );
}

/**
 * Modal de confirmación para cambiar status de varios casos en lote
 * @param {object} props
 * @param {boolean} props.show
 * @param {import("../services/medicalCases").MedicalCase[]} props.selection
 * @param {string} props.newStatus
 * @param {() => void} props.onHide
 * @param {() => void} props.onSave
 * @param {boolean} props.loading
 */
export const ChangeStatusMultipleModal = ({ show, selection, newStatus, onHide, onSave, loading }) => {
  if (!selection || !newStatus) return;
  return (
    <Dialog
      header="Modificar status"
      visible={show}
      style={{ maxWidth: "35rem" }}
      onHide={onHide}
      draggable={false}
    >
      <div className="p-fluid text-center">
        <p>¿Seguro que deseas cambiar el status de {selection.length} casos seleccionados?</p>
        <p><b>Nuevo status: <StatusTag value={newStatus} /></b></p>
      </div>
      <br></br>
      <div className="delete-confirm-buttons-container">
        <Button type="button" label="Cambiar status" onClick={onSave} loading={loading} />
        <Button type="button" label="Cancelar" style={{ backgroundColor: "#999999", borderColor: "#999999" }} onClick={onHide} />
      </div>
    </Dialog>
  );
}
