import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import FormSubHeading from "../components/FormSubHeading";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DAYS } from "../constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useClients, useRequirementTypes, useScaleRequirements } from "../hooks/queries";
import { useState } from "react";
import { MultiSelect } from "primereact/multiselect";

/**
 * Modal to add a new requirement to medical center
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {(data: import("./CreateMedicalCenter").CenterRequirementSlot) => void} props.saveData
 * @param {import("../hooks/queries").MedicalCenterRequirement[]} props.currentCenterRequirements
 * @param {boolean} props.shouldFilterRequirements
 * @param {import("./CreateMedicalCenter").CenterRequirementSlot} props.formState
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setFormState
 */
export default function CenterRequirementModal({
  show,
  onHide,
  saveData,
  currentCenterRequirements,
  shouldFilterRequirements,
  formState,
  setFormState,
}) {

  const [requirementType, setRequirementType] = useState(null);
  const [typeHasSlots, setTypeHasSlots] = useState(false);

  const canSave = Boolean(formState.client && formState.requirements.length);

  // Queries
  const clientsQuery = useClients();

  const requirementTypesQuery = useRequirementTypes();
  const matchRequirementType = requirementTypesQuery.data?.find((reqType) => reqType.name === formState.requirements[0]?.type_requirement_name);

  // Get and filter requirements for dropdown
  const scaleRequirementsQuery = useScaleRequirements(formState.client?.id ?? null, matchRequirementType?.id ?? requirementType);
  let requirementOptions = scaleRequirementsQuery.data ?? [];
  if (shouldFilterRequirements) {
    requirementOptions = requirementOptions.filter(
      (scaleRequirement) => {
        const match = currentCenterRequirements.find((cur) => cur.requirement === scaleRequirement.requirement);
        return !match || (match && match.client !== formState.client)
      }
    )
  }

  /**
   * Handles clicking day button
   * @param {typeof DAYS[number]} day
   */
  const onDayClick = (day) => {
    // If day is already selected, remove from formstate and make button gray
    if (formState.requirements[0].days.find((arrDay) => arrDay.id === day.id)) {
      setFormState({
        ...formState,
        requirements: [{
          ...formState.requirements[0],
          days: formState.requirements[0].days.filter((arrDay) => arrDay.id !== day.id),
        }],
      });
    } else {
      // Add day to form state
      setFormState({
        ...formState,
        requirements: [{
          ...formState.requirements[0],
          days: [
            ...formState.requirements[0].days,
            { ...day, slots: "", }
          ],
        }]
      });
    }
  }

  /**
   * Input for daily slots
   * @param { typeof DAYS[number] & { slots: number | string }} row 
   */
  const dailySlotsInputBodyTemplate = (row) => (
    <InputNumber
      placeholder="Ingrese los cupos para este día"
      value={formState.requirements[0].days.find((day) => day.id === row.id)?.slots}
      onChange={(e) => {
        const newDays = formState.requirements[0].days.map((day) => {
          if (day.id === row.id) return { ...day, slots: e.value};
          return day;
        });
        setFormState({
          ...formState,
          requirements: [{
            ...formState.requirements[0],
            days: newDays
          }]
        });
      }}
    />
  );

  /**
   * Template for requirement dropdown item
   * @param {typeof requirementOptions[number]} option 
   */
  const requirementDropdownItemTemplate = (option) => (
    <span>{option.requirement_name} - REF: {option.price}</span>
  );

  /**
   * Dropdown that allows selecting only one requirement
   */
  const SingleRequirementDropdown = () => (
    <Dropdown
      value={formState.requirements[0] ?? null}
      onChange={(e) => {
        if (e.value) {
          const matchRequirement = scaleRequirementsQuery.data?.find((req) => Number(req.requirement) === Number(e.value));
          if (matchRequirement) {
            setFormState({
              ...formState,
              requirements: [{
                requirement: e.value,
                requirement_name: matchRequirement.requirement_name,
                type_requirement_name: matchRequirement.requirement_type,
                price_ref: matchRequirement.price,
                days: formState.requirements[0]?.days ?? [],
              }]
            });
          }
        }
      }}
      itemTemplate={requirementDropdownItemTemplate}
      loading={scaleRequirementsQuery.isLoading}
      disabled={scaleRequirementsQuery.isLoading || scaleRequirementsQuery.isPending || formState.isEditing}
      options={requirementOptions}
      optionValue="requirement"
      optionLabel="requirement_name"
      placeholder="Seleccionar requerimiento"
      filter
      virtualScrollerOptions={{ itemSize: 48 }}
      emptyMessage="No hay servicios disponibles"
    />
  );

  /**
   * Dropdown that allows for selecting multiple requirements
   */
  const MultipleRequirementDropdown = () => (
    <MultiSelect
      value={formState.requirements.map((req) => req.requirement)}
      onChange={(e) => {
        if (e.value) {
          const newRequirements = e.value.map((value) => {
            const matchRequirement = scaleRequirementsQuery.data?.find((req) => Number(req.requirement) === Number(value));
            if (matchRequirement) {
              return {
                requirement: value,
                requirement_name: matchRequirement.requirement_name,
                type_requirement_name: matchRequirement.requirement_type,
                price_ref: matchRequirement.price,
                days: formState.requirements[0]?.days ?? [],
              }
            }
            return null;
          }).filter((req) => req !== null);
          setFormState({
            ...formState,
            requirements: newRequirements,
          });
        }
      }}
      itemTemplate={requirementDropdownItemTemplate}
      loading={scaleRequirementsQuery.isLoading}
      disabled={scaleRequirementsQuery.isLoading || scaleRequirementsQuery.isPending || formState.isEditing}
      options={requirementOptions}
      optionValue="requirement"
      optionLabel="requirement_name"
      placeholder="Seleccionar requerimiento"
      filter
      virtualScrollerOptions={{ itemSize: 48 }}
      emptyMessage="No hay servicios disponibles"
    />
  );

  return (
    <Dialog visible={show} onHide={onHide} draggable={false} header="Nuevo servicio" style={{ width: "40vw" }}>
      <div className="p-fluid">
        <FormSubHeading title="Servicio" />

        <div className="p-field">
          <label htmlFor="clients">Cliente</label>
          <Dropdown
            value={formState.client?.id ?? null}
            onChange={(e) => setFormState({
              ...formState,
              client: clientsQuery.data.find((c) => c.id === e.value),
              company_client_name: clientsQuery.data.find((c) => c.id === e.value)?.name,
            })}
            loading={clientsQuery.isLoading}
            disabled={clientsQuery.isLoading || formState.isEditing}
            options={clientsQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar clientes"
            filter
          />
        </div>

        <div className="p-field">
          <label htmlFor="requirementType">Tipo de servicio</label>
          <Dropdown
            value={matchRequirementType?.id ?? requirementType}
            onChange={(e) => {
              setRequirementType(e.value)
              const reqType = requirementTypesQuery.data?.find((rt) => rt.id === e.value);
              if (reqType) {
                setTypeHasSlots(reqType.has_space);
              }
            }}
            loading={requirementTypesQuery.isLoading}
            disabled={requirementTypesQuery.isLoading || formState.isEditing}
            options={requirementTypesQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar tipo de servicio"
            filter
          />
        </div>
        
        <div className="p-field">
          <label htmlFor="requirement">Servicio</label>
          {typeHasSlots ? <SingleRequirementDropdown /> : <MultipleRequirementDropdown />}
          {/* <Dropdown
            value={formState.requirement}
            onChange={(e) => {
              if (e.value) {
                const matchRequirement = scaleRequirementsQuery.data?.find((req) => Number(req.requirement) === Number(e.value));
                if (matchRequirement) {
                  setFormState({
                    ...formState,
                    requirement: e.value,
                    requirement_name: matchRequirement.requirement_name,
                    type_requirement_name: matchRequirement.requirement_type,
                    price_ref: matchRequirement.price,
                  });
                }
              }
            }}
            itemTemplate={(option) => (
              <span>{option.requirement_name} - REF: {option.price}</span>
            )}
            loading={scaleRequirementsQuery.isLoading}
            disabled={scaleRequirementsQuery.isLoading || scaleRequirementsQuery.isPending}
            options={requirementOptions}
            optionValue="requirement"
            optionLabel="requirement_name"
            placeholder="Seleccionar requerimiento"
            filter
            virtualScrollerOptions={{ itemSize: 48 }}
            emptyMessage="No hay servicios disponibles"
          /> */}
        </div>

        {typeHasSlots && (
          <>
            <FormSubHeading title="Disponibilidad" />
            <div className="mb-3">
              <span>Seleccione los días de atención:</span>
              <div className="day-row-selector my-2">
                { DAYS.map((day) => (
                  <Button
                    key={day.id}
                    label={day.shortLabel}
                    aria-label={day.fullLabel}
                    onClick={() => onDayClick(day)}
                    className={formState.requirements[0]?.days.find((formDays) => formDays.id === day.id) ? "" : "btn-off"}
                  />
                )) }
              </div>
              {!!formState.requirements[0]?.days.length && (
                <DataTable value={formState.requirements[0]?.days} dataKey="id">
                  <Column header="Día" field="fullLabel" />
                  <Column header="Cupos disponibles" body={dailySlotsInputBodyTemplate}  />
                </DataTable>
              )}
            </div>
          </>
        )}
        <Button
          type="button"
          onClick={() => {
            saveData(formState);
            setRequirementType(null);
          }}
          label="Guardar"
          disabled={!canSave}
        />
      </div>
    </Dialog>
  );
}
