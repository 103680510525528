import React from "react";

import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

export default function TemplateDemo({ setIsActive }) {
  // const itemRenderer = (item) => (
  //   <a className="flex align-items-center p-menuitem-link">
  //     <span className={item.icon} />
  //     <span className="mx-2">{item.label}</span>
  //     {item.badge && <Badge className="ml-auto" value={item.badge} />}
  //     {item.shortcut && (
  //       <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
  //         {item.shortcut}
  //       </span>
  //     )}
  //   </a>
  // );

  const startContent = (
    <Button icon="pi pi-bars" text raised onClick={setIsActive}></Button>
    // <div className="flex align-items-center gap-2">
    //   <Avatar
    //     style={{width: "11rem", paddingRight: "1rem"}}
    //     size="large"
    //     image="https://
    //   />
    // </div>
  );

  const centerContent = (
    <>
      {/* <img alt="logo" className="hidden md:block" src="/logo-la-mundial.png" height="40"></img>
      <img alt="logo" className="md:hidden" src="/isotipo-la-mundial.png" height="40"></img> */}
    </>
  );
  const endContent = (
    <>
      <img
        alt="logo"
        src="/logo.png"
        height="40"
        className="hidden md:block mr-2"
      ></img>
      <img
        alt="logo"
        src="/isologo-fin.png"
        height="40"
        className="md:hidden mr-2"
      ></img>
    </>
    // if
  );

  return (
    <div className="card">
      {/* <Menubar model={items} start={start} end={end} /> */}
      <Toolbar start={startContent} center={centerContent} end={endContent} />
    </div>
  );
}
