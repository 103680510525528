import React, { useState } from "react";
// import { Badge } from "primereact/badge";

import { Panel } from "primereact/panel";
import "./Dashboard.css";
import TopBar from "../TopBar/TopBar";
import { Menu } from "primereact/menu";
import { Link, ScrollRestoration, useLocation } from "react-router-dom";
import { ROUTE_ALLOWED_ROLES, UserRole } from "../constants";

/**
 * Main dashboard box
 * @param {object} props
 * @param {string} props.headerTitle
 * @param {string | undefined} props.backButtonUrl
 * @param {string | undefined} props.fixedWidth
 */
export default function Dashboard({
  headerTitle,
  backButtonUrl,
  fixedWidth,
  children,
}) {
  const location = useLocation();

  /**
   * Renders an item in the sidebar
   * @param {object} item
   * @param {string} item.label
   * @param {string} item.icon
   * @param {string} item.url
   * @param {string[]} item.user
   * @param {RegExp[] | undefined} item.active
   */
  const itemRenderer = (item) => {
    let activeClassName = "";
    if (
      location.pathname === item.url ||
      (item.active &&
        item.active.some((regex) => regex.test(location.pathname)))
    ) {
      activeClassName = "active";
    }

    return (
      <div className={`p-menuitem-content ${activeClassName}`}>
        <Link to={item.url} className="flex align-items-center p-menuitem-link">
          <i className={item.icon} aria-hidden="true" />
          <span className="mx-2">{item.label}</span>
        </Link>
      </div>
    );
  };

  // Rutas disponibles del dashboard con sus permisos
  const items = [
    {
      label: "Casos Médicos",
      items: [
        {
          label: "Crear caso",
          icon: "pi pi-plus",
          url: "/casos/nuevo",
          user: ROUTE_ALLOWED_ROLES["/casos/nuevo"],
        },
        {
          label: "Listado de casos",
          icon: "pi pi-list-check",
          url: "/casos",
          user: ROUTE_ALLOWED_ROLES["/casos"],
          active: [/\/casos\/\d+/],
        },
        {
          label: "Requerimientos médicos",
          icon: "pi pi-file-edit",
          url: "/requerimientos",
          user: ROUTE_ALLOWED_ROLES["/requerimientos"],
        },
      ],
    },
    {
      label: "Anticipos",
      items: [
        {
          label: "Crear anticipo",
          icon: "pi pi-plus",
          url: "/anticipos/nuevo",
          user: ROUTE_ALLOWED_ROLES["/anticipos/nuevo"],
        },
        {
          label: "Anticipos",
          icon: "pi pi-wallet",
          url: "/anticipos",
          user: ROUTE_ALLOWED_ROLES["/anticipos"],
          active: [/\/anticipos\/proveedores\/\d+/],
        },
      ],
    },
    {
      label: "Baremos",
      items: [
        {
          label: "Listado de Baremos",
          icon: "pi pi-percentage",
          url: "/baremos",
          user: ROUTE_ALLOWED_ROLES["/baremos"],
          active: [/\/baremos\/\d+/],
        },
      ],
    },
    {
      label: "Proveedores",
      items: [
        {
          label: "Listado de Proveedores",
          icon: "pi pi-building-columns",
          url: "/proveedores",
          user: ROUTE_ALLOWED_ROLES["/proveedores"],
          active: [/\/proveedores\/nuevo/, /\/proveedores\/editar\/\d+/],
        },
      ],
    },
    {
      label: "Facturas",
      items: [
        {
          label: "Cargar factura",
          icon: "pi pi-plus",
          url: "/centro-medico/facturas/nueva",
          user: ROUTE_ALLOWED_ROLES["/centro-medico/facturas/nueva"],
        },
        {
          label: "Listado de facturas",
          icon: "pi pi-receipt",
          url: "/centro-medico/facturas",
          user: ROUTE_ALLOWED_ROLES["/centro-medico/facturas"],
        },
      ],
    },
    {
      label: "Usuarios",
      items: [
        {
          label: "Listado de usuarios",
          icon: "pi pi-users",
          url: "/usuarios",
          user: ROUTE_ALLOWED_ROLES["/usuarios"],
        },
      ],
    },
    {
      label: "Cerrar sesión",
      items: [
        {
          label: "Cerrar sesión",
          icon: "pi pi-sign-out",
          url: "/logout",
          user: ROUTE_ALLOWED_ROLES["/logout"],
        },
      ],
    },
  ];

  const itemsFilter = (item) => {
    const role = localStorage.getItem("role");
    if (!role) return false;
    // if (!role) return true;
    if (role === UserRole.MASTER) {
      return true;
    }
    return item.user.includes(role);
  };

  const [isActive, setIsActive] = useState(true);

  return (
    <div className="layout-dashboard">
      <div className={isActive ? "layout-sidebar " : "sidebar-hidden"}>
        <div className="mb-4">
          {/* <Avatar label="LM" size="xlarge" shape="circle" /> */}
          {/* <Badge value="8" severity="info" /> */}
          <img alt="logo" className="hidden md:block" src="/logo-la-mundial.png" height="40"></img>
        </div>
        <Menu
          model={items
            .map((routeGroup) => ({
              ...routeGroup,
              items: routeGroup.items
                .filter(itemsFilter)
                .map((item) => ({ ...item, template: itemRenderer })),
            }))
            .filter((routeGroup) => !!routeGroup.items.length)}
        />
      </div>

      <div className={isActive ? "layout-content":"layout-content layout-inactive"}>
        <TopBar setIsActive={() => setIsActive(!isActive)}></TopBar>
        <div className="layout-main-container">
          <div className="layout-main">
            {backButtonUrl && (
              <Link to={backButtonUrl} className="back-link">
                <i className="pi pi-chevron-left" aria-hidden="true"></i>
                <span>Volver</span>
              </Link>
            )}
            <Panel
              header={headerTitle ?? "Dashboard"}
              style={fixedWidth ? { width: fixedWidth } : {}}
            >
              {children}
            </Panel>
          </div>
        </div>
      </div>
      <ScrollRestoration />
    </div>
  );
}
