import { InputText } from "primereact/inputtext";
import FormSubHeading from "../components/FormSubHeading";
import Dashboard from "../Layout/Dashboard";
import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { useClients, useRequirements } from "../hooks/queries";
import { useMutation } from "@tanstack/react-query";
import { addScaleWithRequirements } from "../services/baremos";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import BaremoRequirementsTable from "./BaremoRequirementsTable";
import RequirementModal from "./BaremoRequirementModal";

/**
 * Form to view and edit scale
 */
export default function CreateBaremo() {

  const [name, setName] = useState("");
  const [clients, setClients] = useState([]);
  const [requirements, setRequirements] = useState([]);

  const canSave = name.trim() !== "" 
    && requirements.length
    && requirements.every((req) => req.requirement && req.price);

  // Queries for select
  const requirementsQuery = useRequirements();
  const clientsQuery = useClients(true);

  // State for form
  const [initialData, setInitialData] = useState({
    requirement: null,
    price: "",
    action: "create",
  });
  const [disableRequirementDropdown, setDisableRequirementDropdown] = useState(false);

  // For modal
  const [showModal, setShowModal] = useState(false);

  const toast = useRef(null);

  // Add baremo
  const createMutation = useMutation({
    mutationFn: addScaleWithRequirements,
    onSuccess: ({ type, message }) => {
      if (type === "error") {
        toast.current.show({ severity: "error", summary: "Error", detail: message });
      } else {
        toast.current.show({ severity: "success", summary: "Éxito", detail: message });
        setName("");
        setClients([]);
        setRequirements([]);
      }
    }
  })

  /**
   * Buttons for adding requirements and saving baremo
   */
  const AddAndSaveBtns = () => (
    <div className="btn-container">
      <Button
        label="Agregar servicio"
        icon="pi pi-plus"
        type="button"
        onClick={() => {
          setInitialData({
            requirement: null,
            price: "",
            action: "create",
          });
          setShowModal(true);
          setDisableRequirementDropdown(false);
        }}
      />
      <Button
        label="Guardar baremo"
        icon="pi pi-save"
        severity="success"
        type="button"
        disabled={!canSave}
        loading={createMutation.isPending}
        onClick={() => {
          createMutation.mutate({ name, clients, insurance: 1, scaleRequirements: requirements });
        }}
      />
    </div>
  );

  return (
    <Dashboard headerTitle="Crear baremo" backButtonUrl="/baremos" fixedWidth="800px">
      <Toast ref={toast} position="top-center" />

      <form className="p-fluid">

        <div className="pb-3">
          <FormSubHeading title="Datos del baremo"/>
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="p-field">
            <label htmlFor="clients">Cliente</label>
            <MultiSelect
              id="clients"
              name="clients"
              options={clientsQuery.data ?? []}
              dataKey="id"
              optionLabel="name"
              value={clients}
              onChange={(e) => setClients(e.value)}
              placeholder="Seleccione los clientes asociados"
              emptyMessage="No hay clientes disponibles"
            />
          </div>
        </div>

        <div className="pb-3 center-requirement-config">
          <FormSubHeading title="Servicios" />
          <AddAndSaveBtns />

          <BaremoRequirementsTable
            value={requirements}
            onClickEdit={(row) => {
              return () => {
                setInitialData(row);
                setShowModal(true);
                setDisableRequirementDropdown(true);
              }
            }}
            onClickDelete={(row) => {
              return () => {
                const newRequirements = requirements.filter((req) => req.requirement !== row.requirement);
                setRequirements(newRequirements);
              }
            }}
          />

          <RequirementModal
            show={showModal}
            onHide={() => {
              if (showModal) setShowModal(false);
              if (initialData) setInitialData({
                requirement: null,
                price: "",
              });
              setDisableRequirementDropdown(false);
            }}
            saveData={(req) => {
              const newReq = requirements.filter((r) => r.requirement !== req.requirement);
              setRequirements([...newReq, req]);
              setInitialData({
                requirement: null,
                price: "",
                action: "create",
              });
              setDisableRequirementDropdown(false);
            }}
            requirementsQuery={requirementsQuery}
            formState={initialData}
            setFormState={setInitialData}
            requirementsFilter={(req) => !requirements.find((r) => r.requirement === req.id)}
            disableDropdown={disableRequirementDropdown}
          />
          <AddAndSaveBtns />
        </div>
      </form>
    </Dashboard>
  );
}
