import { InputText } from "primereact/inputtext";
import FormSubHeading from "../components/FormSubHeading";
import Dashboard from "../Layout/Dashboard";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import "./MedicalCenters.css";
import { addMedicalCenter } from "../services/medicalCenters";
import { useNavigate } from "react-router-dom";
import CenterRequirementsTable from "./CenterRequirementsTable";
import RequirementModal from "./CenterRequirementModal";

// const INITIAL_DATA = {
//   client: null,
//   requirement: null,
//   requirement_name: "",
//   type_requirement_name: "",
//   days: [],
//   price_ref: 0,
// };

/**
 * @typedef SlotRequirement
 * @property {number} requirement
 * @property {string} requirement_name
 * @property {string} type_requirement_name
 * @property {object[]} days
 * @property {number} price_ref
 */

/**
 * @typedef {object} CenterRequirementSlot
 * @property {{id: number, name: string}?} client
 * @property {SlotRequirement[]} requirements
 * @property {boolean?} isEditing
 */

/**@type {CenterRequirementSlot} */
const INITIAL_DATA_MULTIPLE = {
  client: null,
  requirements: [],
}

/**
 * Form to create new medical centers
 */
export default function CreateMedicalCenter() {

  // State for form
  const [name, setName] = useState("");
  const [slots, setSlots] = useState([]);

  // For modal
  const [showModal, setShowModal] = useState(false);
  const [shouldFilterReq, setShouldFilterReq] = useState(true);

  // State for requirement modal
  const [initialData, setInitialData] = useState(INITIAL_DATA_MULTIPLE);

  // Crear centro
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: addMedicalCenter,
    onSuccess: () => {
      // Refresh
      queryClient.invalidateQueries({
        queryKey: ["medicalCenters"],
      });
      navigate("/proveedores");
    }
  });

  const canSave = name.trim() !== "" && 
    slots.every((slot) => slot.requirement && slot.days.every((day) => day.slots && day.slots > 0));

  /**
   * Buttons to add and edit requirements
   */
  const AddAndEditBtns = () => (
    <div className="btn-container">
      <Button
        label="Agregar servicio"
        icon="pi pi-plus"
        type="button"
        onClick={() => {
          setInitialData(INITIAL_DATA_MULTIPLE);
          setShowModal(true);
        }}
      />
      <Button
        label="Guardar proveedor"
        icon="pi pi-save"
        severity="success"
        type="button"
        disabled={!canSave}
        onClick={() => createMutation.mutate({ name, requirements: slots })}
      />
    </div>
  );

  /**
   * Save data from modal
   * @param {CenterRequirementSlot} requirements 
   */
  const saveData = (requirements) => {
    setSlots([...slots, ...requirements.requirements.map((req) => ({ clients: requirements.client, ...req }))]);
    setInitialData(INITIAL_DATA_MULTIPLE);
    if (!shouldFilterReq) {
      setShouldFilterReq(true);
    }
  }

  return (
    <Dashboard headerTitle="Nuevo Proveedor" backButtonUrl="/proveedores" fixedWidth="850px">
      <form className="p-fluid">
        <div className="pb-3">
          <FormSubHeading title="Datos del proveedor"/>
          <div className="p-field">
            <label htmlFor="name">Nombre del proveedor</label>
            <InputText
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              autoFocus
              placeholder="Ingrese el nombre del proveedor"
            />
          </div>
        </div>
        <div className="pb-3 center-requirement-config">
          <FormSubHeading title="Disponibilidad de servicios" />
          <AddAndEditBtns />

          <CenterRequirementsTable
            value={slots}
            onClickEdit={(row) => {
              return () => {
                setInitialData(row);
                setShouldFilterReq(false);
                setShowModal(true);
              }
            }}
            onClickDelete={(row) => {
              return () => {
                const newSlots = slots.filter(slot => slot.requirement !== row.requirement);
                setSlots(newSlots);
              }
            }}
          />

          <AddAndEditBtns />
          <RequirementModal
            show={showModal}
            onHide={() => {
              if (showModal) {
                setShowModal(false);
                if (!shouldFilterReq) {
                  setShouldFilterReq(true);
                }
              }
            }}
            saveData={saveData}
            currentCenterRequirements={slots}
            shouldFilterRequirements={shouldFilterReq}
            formState={initialData}
            setFormState={setInitialData}
          />
        </div>
      </form>
    </Dashboard>
  );
}
